@media only screen and (min-width: 990px) {
    .menu-bar{
        display: none;
    }
}
@media only screen and (max-width: 990px) {
    .arrows-slide.left {
        left: 6%;
    }
    .arrows-slide.right {
        right: -10.3%;
    }
    .taste-content h3{
        font-size: 16px;
    }
    .taste-content h1{
        font-size: 100px;
    }
    .taste-content{
        padding: 3rem 0;  
        display: flex;
        align-items: center;
        justify-content: center;
        height: 115px;
    }
    iframe{
        height: 300px;
    }
}