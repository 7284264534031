@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
    font-family: Kanit !important;
    padding: 0;
    margin: 0;
    background-color: #fff;
}
.shadow-header{
    box-shadow: 0 0 5px #f1f1f1;
    position: relative;
    background-color: #fff;
}
header.main-header{
    padding: 10px;
    background-color: #fff;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
}

header.main-header .logoHeader{
    width: 70px;
}

header.main-header .menu-flex{
    display: flex;
    align-items: center;
    width: 100%;
}

header.main-header .header-menu{
    margin-left: auto;
    margin-top: 30px;
    display: flex;
    align-items: center;
}

header.main-header .header-menu .header-link{
    text-decoration: none;
    font-size: 16px;
    color: #363636;
    margin-left: 25px;
    background-color: transparent;
    border: 0;
}

header.main-header .header-menu .header-link i{
    width: 25px;
}

header.main-header .menu-top{
    position: absolute;
    top: 0px;
    right: 0;
    width: 350px;
    padding: 5px 25px;
    background-color: #f1f1f1;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    font-size: 14px;
    display: flex;
    justify-content: center;
}

.cart-header{
    position: absolute;
    font-size: 13px;
    background-color: #ff8787;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: 11px;
    left: 10px;
    color: #fff;
}

.five-img{
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    flex-wrap: wrap;
    justify-content: center;
}

.five-img-item{
    width: 20%;
    padding: 10px;
}

.product-white .product-card{
    background-color: #ffff;
}

.product-white .addcart-btn{
    width: 100%;
    border: 0;
    background-color: #f1f1f1;
    border-radius: 5px;
    margin-top: 10px;
    padding: 5px 0;
    font-size: 14px;
}


.product-card{
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 10px;
}

.product-card img{
    border-radius: 10px;
    max-height: 210px;
}

.normal-product img{
    height: 100%;
    max-height: 280px !important;
}
.bootom-footer{
    /* margin-top: 50px; */
    background-color: #f1f1f1;
    padding: 20px 0;
}
.text-indent30{
    text-indent: 30px;
}
.product-name{
    font-weight: 300;
}

.addcart-btn{
    width: 100%;
    border: 0;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 10px;
    padding: 5px 0;
    font-size: 14px;
}

.slide-item{
    padding: 15px;
}

.sm-d-block{
    display: none;
}


.arrows-slide{
    position: absolute;
    top: 50%;
    transform: translate(-50% , -50%);
    font-size: 40px;
    color: rgba(121, 121, 121, 0.445) !important;
    z-index: 1;
    transition: 0.25s;
    cursor: pointer;
}

.arrows-slide:hover{
    color: rgb(121, 121, 121) !important;
}


.arrows-slide.left{
    left: 0%;
}

.arrows-slide.right{
    right: -3.3%;
}

.slick-dots{
    display: flex !important;
    justify-content: center;
    list-style: none;
    bottom: -40px !important;
}

.slick-dots li {
    margin: 0 0.3rem;
}


.slick-dots li button{
    background-color: #f5f5f5;
    border: 0;
    color: transparent;
    width: 20px;
    height: 20px;
    border-radius: 50px;
}

.slick-dots li.slick-active button{
    background-color: #e2e2e2;
}

.slick-slide .col-check{
    width: 100% !important;
}

.mx--15{
    margin-left: -15px;
    margin-right: -15px;
}

.bg-lightgray{
    padding: 1.5rem 1rem;
    background-color: #f1f1f1;
    border-radius: 15px;
}

.select-taste-group{
    display: flex;
    flex-wrap: wrap;
}

.slick-slide{
    padding: 0 12px;
}

.taste-item{
    padding: 10px;
    width: 33.33%;
}

.taste-item a{
    text-decoration: none;
    color: #3b3b3b !important;
}

.taste-content{
    text-align: center;
    padding: 5rem 0;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
}


.taste-content h1{
    position: absolute;
    top: -20px;
    right: -14px;
    font-size: 180px;
    color: rgba(0, 0, 0, 0.089);
    font-weight: 700;
    transition: 0.25s;
}

.taste-content:hover h1{
    color: rgba(0, 0, 0, 0.205);
}

.taste-content:hover{
    cursor: pointer;
}

.taste-content.first{
    background-color: #fdcce0;
}

.taste-content.second{
    background-color: #ffcac1;
}

.taste-content.third{
    background-color: #ffb5b5;
}

.taste-content.four{
    background-color: #ffe1a8;
}

.taste-content.five{
    background-color: #f1b4f1;
}

.taste-content.six{
    background-color: #ff9466;
}

.taste-content.six h3{
    color: #b32100;
}

.margin-footer{
    height: 170px;
}

.selectedTaste{
    
}

.selectedTaste .taste-item-header{
    padding: 20px;
    border-radius: 20px;
}
.selectedTaste h1{
    color: #fff;
    font-size: 1.5rem;
}
.selectedTaste .original{
    background-color: #fdcce0;
}
.selectedTaste .choco_berries{
    background-color: #ffcac1;
}
.selectedTaste .yuzo_berries{
    background-color: #ffb5b5;
}
.selectedTaste .yuzo_premium{
    background-color: #ffe1a8;
}
.selectedTaste .fruity_berries{
    background-color: #f1b4f1;
}
.selectedTaste .special_event{
    background-color: #ff9466;
}

.selectedTaste .special_event h1{
    color: #b32100;
}

.hover{
    cursor: pointer;
}

.ContactUsPage{
   
}



.contact-us-title{
    margin: 0 auto;
    background-color: #f1f1f1;
    padding:  7px 50px;
    border-radius: 30px;
}

.delete-image{
    background-color: #b32100;
    color: #fff;
    border: 0 ;
    outline: none !important;
    border-radius: 50px;
    font-size: 14px;
    display: inline-flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
}

.account-tab{
    width: 100%;
    text-align: center;
    padding: 0.8rem 0;
    border-radius: 10px;
    text-decoration: none;
    color: unset !important;
    display: inline-flex;
    justify-content: center;
    cursor: pointer;
}

.account-tab.active{
    background-color: #ccc;
}

.SelectSizePage{
    background-color: #f1f1f1;
}

.SelectModelPage{
    background-color: #f1f1f1;
}

.select-size-card{
    background-color: #fff;
    border-radius: 15px;
    padding: 30px;
    box-shadow:  0 0 10px rgb(235, 235, 235);
}

.carousel-indicators button{
    width: 15px !important;
    height: 15px !important;
    border-radius: 50% !important;
}

.product-card .image{
    width: 100%;
    height: 270px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    margin-bottom: 15px;
}
.sizeDes p{
    display: flex;
    align-items: center;
}

.sizeDes p span{
    margin-right: 10px;
    background-color: #a9a9a9;
    width: 23px;
    height: 23px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    color: #ffffff;
}

.sizeDes p span.free{
    /* background-color: #f8b422; */
}

.select-size-group{
    display: flex;
    margin-top: 25px;
    margin-left: -10px;
    margin-right: -10px;
}

.select-size-item{
    width: 20%;
    padding: 0 10px;
}

.thankyou-card{
    background-color: #f1f1f1;
    padding: 10px 30px;
    border-radius: 15px;
    box-shadow: 3px 3px 0 #ccc;
}

.thankyou-title{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 25px;
    background-color: #fff;
    border-radius: 15px;
    padding: 10px 0;
    font-weight: 400;
}

.thankyou-title span{
    font-size: 21px;
}

.complete{
    margin-top: 15px;
    display: inline-flex;
    width: 100%;
    background-color: #ccc;
    color: rgb(0, 0, 0) !important;
    text-decoration: none;
    padding: 10px;
    border-radius: 10px;
    text-decoration: center;
    justify-content: center;
    transition: 0.25s;
}

.hover{
    cursor: pointer;
}

.order-code-card{
    background-color: #eb5d3d;
    padding: 15px 0;
    margin-bottom: 15px;
    border-radius: 15px;
    transition: 0.25s;
}

.order-code-card:hover{
    background-color: #c7462a;
}

.order-code-card h4{
    cursor: pointer;
}

.complete:hover{
    background-color: rgb(117, 117, 117);
    color: #fff !important;
}

.step{
    width: 30px;
    height: 30px;
    margin: auto;
    background-color: #ccc;
    text-align: center;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    border-radius: 50px;
    color: rgb(201, 24, 24);
    margin-bottom: 15px;
}


.select-size{
    background-color: #f1f1f1;
    text-align: center;
    height: 100px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    cursor: pointer;
    font-weight: 300;
}

.add-friend{
    border: 0;
    color: #ffffff !important; 
    height: 60px;
    padding: 0 25px;
    border-radius: 8px;
    font-size: 13px;
    width: 100%;
    text-decoration: none !important;
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 12px;
}


.add-friend span{
    font-weight: 600;
}

.add-friend.facebook{
    background-color: #1877f2;
}

.add-friend.line{
    background-color: #06c152;
}

.select-size.active{
    background-color: #a8a8a8;
    color: #fff;
    box-shadow: 0 0 5px #ccc;
    font-weight: 400;
    position: relative;
}

.select-size.active span{
    position: relative;
    display: grid;
}

.select-size.active span i {
    /* position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(-50%, -50%); */
}

.select-size.active::after{
    /* content: "เลือกแล้ว"; */
    position: absolute;
    top: 87%;
    left: 50%;
    transform: translate(-50% , -50%);
    color: #fff;
    font-size: 13px;
}

.select-size-container.container{
   max-width: 990px;
}

.size-title-selected{
    margin-left: auto;
    background-color: #fff;
    font-weight: 400;
    border-radius: 30px;
    padding: 0.3rem 2.5rem;
}

.text-right{
    text-align: right;
}

.choose-btn{
    border-radius: 30px;
    border: 0;
    background-color: #a8a8a8;
    padding: 0.3rem 2.5rem;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 150px !important;
    color: #fff !important;
    text-align: center;
    text-decoration: none;
}

.comfirm-model-btn{
    border-radius: 30px;
    border: 0;
    background-color: #f1f1f1;
    padding: 0.3rem 2.5rem;
    width: 150px;
    color: #363636 !important;
    text-align: center;
    text-decoration: none;
}

.model-select-button{
    padding: 0.5rem;
    text-align: center;
    box-shadow: 0 0 5px #f1f1f1;
    border-radius: 10px;
    border: 1px solid #d3d3d3;
    cursor: pointer;
}
.model-select-button.active{
    background-color: #f1f1f1;
}


.amount-select{
    display: flex;
    justify-content: center;
}

.amount-select input{
    width: 60px;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none !important;
    padding: 5px 0px;
    text-align: center;
}

.amount-select button{
    background-color: #f1f1f1;
    border: none;
    color: rgb(58, 58, 58);
    font-size: 13px;
    width: 30px;
    border-radius: 5px;
    outline: none !important;
}

.amount-select button.plus{
    margin-left: 15px;
}

.amount-select button.minus{
    margin-right: 15px;
}

.amount-select .total-price{
    font-size: 18px;
}

.amount-select .total-price span{
    color: rgb(202, 47, 47);
}

.input-theme{
    display: block;
    width: 100%;
    border: 0;
    border: 1px solid #cccc;
    background-color: #fafafa;
    border-radius: 10px;
    padding: 10px 15px;
    padding-top: 30px;
    outline: none;
    margin-top: 5px;
}

.view-all{
    margin-left: auto;
    background-color: #f1f1f1;
    color: #363636 !important;
    text-decoration: none;
    padding: 5px 30px;
    border-radius: 30px;
}

.menu-bar{
    position: fixed;
    width: 55%;
    height: 100%;
    background-color: #fff;
    top: 0;
    left: -100%;
    z-index: -1;
    padding: 15px;
    box-shadow: 0 0 4px #ccc;
    transition: 0.25s;
}

.menubar-logo{
    width: 50px;
}

.menu-bar ul{
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
}

.menu-bar ul li{
    margin-bottom: 20px;
}


.menu-bar ul li a{
    color: #363636 !important;
    text-decoration: none !important;
    font-size: 14px;
}

.menu-bar ul li.active a{
    width: 100%;
    display: inline-flex;
    background-color: #f1f1f1;
    align-items: center;
    padding: 10px 20px;
    border-radius: 15px;
}

.menu-bar ul li i{
    width: 20px;
}

.menubar-header{
    display: flex;
    align-items: center;
    width: 100%;
}

.close-bar{
    margin-left: auto;
    background-color: #f18383;
    width: 27px;
    text-align: center;
    border-radius: 50px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.whiteCard{
    background-color: #ffff;
    padding: 30px 15px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgb(235, 235, 235);
}

.form-group{
    margin-bottom: 1rem;
    position: relative;
}

input{
    outline: none !important;
}

.form-group > .form-control {
    padding: 0.7rem 0.5rem;
    border: 0;
    padding-top: 1.8rem;
    outline: none !important;
    border: 1px solid #f1f1f1;
    background-color: #fcfcfc;
}

.form-group > label{
    position: absolute;
    transition: 0.2s;
    font-size: 13px;
    top: 10px;
    left: 10px;
    font-family: 300;
    /* color: #3636367c; */
}

.link-normal{
    color: #000 !important;
    text-decoration: none !important;
}

.welcome-btn{
    background-color: #ebebeb;
    padding: 10px 20px;
    display: inline-flex;
    width: 100%;
    justify-content: center;
    border-radius: 30px;
    margin-top: 30px;
    color: #000 !important;
    font-size: 18px;
    text-decoration: none;
    box-shadow: 0 0 5px #a7a7a7cc;
}

#date-modal{
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0;
    background-color: #000;
    z-index: 1;
}


.submit-btn{
    background-color: #dbdbdb;
    border:0;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    padding: 0.6rem 0;
}

.new-user-btn{
    background-color: #df4e4e;
    border:0;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    padding: 0.6rem 0;
    color: #fff !important;
    text-decoration: none;
}

.qs-form{
    color: #363636 !important;
    text-decoration: none;
}

.ml-auto{
    margin-left: auto;
}

.loginSection{
    height: 710px;
}

.loginBg{
    background-image: url(../images/login-bg.jpg);
    background-position: center;
    background-size: cover;
}

.size13{
    font-size: 13px;
}

.carousel-control-next, .carousel-control-prev{
    z-index: 0 !important;
}

.finalHeaderSelected{
    background-color: #f1f1f1;
    display: inline-flex;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-size: 18px;
}

.cart-card{
    padding: 1rem 1rem;
    background-color: #f1f1f1;
    border-radius: 15px;
    margin-top: 15px;
   
}

.cart-form-group {
    margin-bottom: 15px;
    display: grid;
}

.cart-form-group label{
    color: rgb(121, 121, 121);
    font-size: 14px;
}

.cart-form-control{
    border-radius: 5px;
    margin-top: 10px;
    border: 0;
    padding: 10px 15px;
    outline: none !important;
    width: 100%;
}

.brs-r-0{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.brs-l-0{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.br-1-light{
    border-right: 1px solid #f1f1f1;
}

.checkout-btn{
    background-color: #ccc;
    border-radius: 7px;
    padding: 0.4rem 1rem;
    text-align: center;
    border: 0;
    width: 100%;
}

.cart-image{
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
}
.color-list{
    margin-bottom: 1rem;
}
.color-list i{
    margin-right: 10px;
}

.color-list.yellow i{
    color: #f7e452;
}

.color-list.orange i{
    color: #f28920;
}

.color-list.lightblue i{
    color: #88bbf9;
}

.color-list.purple i{
    color: #7e85c1;
}

.color-list.lightpink i{
    color: #fdd2e2;
}

.color-list.darkpink i{
    color: #e76293;
}

.color-list.white i{
    color: #e4e4e4;
}

.color-list.darkgreen i{
    color: #53960c;
}

.color-list.lightgreen i{
    color: #82c53a;
}
.xsm-d-block{
    display: none;
}

.contact-info{
    margin-bottom: 25px;
}

.select-taste-title{
    display: inline-flex;
    margin-left: auto;
    margin-right: auto;
    background-color: #ccc;
    padding: 15px 40px;
    border-radius: 50px;
    box-shadow: 2px 2px 0px #bbbbbb;
    animation-name: tasteTitle;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
}

@keyframes tasteTitle {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.05);
    }
    100%{
        transform: scale(1);
    }
}



.contact-info i {
    font-size: 20px;
    width: 25px;
}

.icon-adress{
    color: #ca1028;
}

.icon-time{
    color: #813705;
}

.icon-phone{
    color: #c47540;
}

.icon-email{
    color: #3dcee7;
}

.icon-line{
    color: #22c713;
}

.icon-facebook{
    color: #0d47c5;
}

.icon-line{
    color: #22c713;
}

.icon-ig{
    color: #d540e9;
}


@media only screen and (min-width: 991px) {
    .space-bottom{
        margin-bottom: 12.5rem;
    }
    .sm-d-block{
        display: none;
    }
}

@media only screen and (max-width: 990px) {
    .checkout-btn{
        font-size: 16px !important;
        padding: 10px 0 !important;
    }
    .sm-d-none{
        display: none;
    }
    .cart-card th  , td{
        font-size: 14px;
    }
    .cart-card button i {
        font-size: 12px;
    }
    .cart-card button{
        font-size: 12px;
        padding: 0px 4px;
    }
    .sm-mb-53px{
        margin-bottom: 55px;
        margin-top: 1.5rem;
    }
    .select-size{
        font-size: 13px;
        display: grid;
    }
    .select-size i{
        margin-bottom: -30px;
        margin-right: 0rem !important;
    }
    .select-size-card{
        padding-left: 10px;
        padding-right: 10px;
    }
    .select-taste-group{
        margin-left: -10px;
        margin-right: -10px;
    }
    .product-card .image{
        height: 133px;
    }
    .five-img-item{
        width: 50%;
        padding: 10px;
    }
    .menu-top{
        width: 275px !important;
        font-size: 12px !important;
    }
    .sm-d-none{
        display: none;
    }
    header.main-header .logoHeader{
        width: 55px;
    }
    .sm-d-block{
        display: inline-flex;
    }
    .select-size-group {
        flex-wrap: wrap;
    }
    .select-size-item{
        width: 33%;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 360px) {
    .xsm-d-block{
        display: block;
    }
}