.admin-header{
    background-color: #f1f1f1;
    box-shadow:  0 0 5px #ccc;
}

.admin-header .header-logo{
    width: 50px;
}

.admin-header .header-list{
    list-style: none;
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
}

.admin-header .header-list .first-menu{
    margin-left: auto !important;
}

.admin-header .header-list .header-item{
    margin-left: 35px;
    position: relative;
}

.admin-header .header-list .header-item.active::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    background-color:rgb(31, 31, 31);
    
    top: 180%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.admin-header .header-list .header-link{
    text-decoration: none;
    color: rgb(90, 90, 90);
    transition: 0.2s;
}


.admin-header .header-list .header-item.active .header-link{
    color: rgb(31, 31, 31);
}

.admin-header .header-list .header-item .header-link:hover{
    color: rgb(31, 31, 31);
}


.admin-card{
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 10px;
}

.pd-admin-card{
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 10px;
    height: auto;
}

.pd-admin-card img{
    border-radius: 10px;
}

.delete-pd-admin{
    background-color: rgb(255, 66, 66);
    color: #fff;
    border: 0;
    border-radius: 50%;
    font-size: 14px;
    width: 25px;
    height: 25px;
}

.edit-pd-admin{
    background-color: rgb(255, 214, 34);
    color: #fff;
    border: 0;
    border-radius: 50%;
    font-size: 14px;
    width: 25px;
    height: 25px;
}

.pd-admin-image{
    background-position: center;
    background-size: cover;
    width: 100%;
    border-radius: 10px;
    height: 190px;
}

.pd-admin-name{
    font-size: 14px;
}

.pd-admin-card span strong{
    font-size: 14px;
}
